
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import Contoso from './assets/medmate_logo.png'

import Chat from './pages/chat/Chat';
import Layout from './pages/layout/Layout';
import NoPage from './pages/NoPage';
import { AppStateProvider } from './state/AppProvider';

import './index.css';

initializeIcons();

// MSAL configuration
const msalConfig = {
  auth: {
    clientId: "646756df-f604-49a0-82a7-1b1056ec72f2", // "646756df-f604-49a0-82a7-1b1056ec72f2",  // Replace with your Azure AD B2C client ID
    authority: "https://login.microsoftonline.com/medmatenl.onmicrosoft.com",  
    redirectUri: "https://test-medmate.swedencentral.cloudapp.azure.com/"
  },
  cache: {
    cacheLocation: "sessionStorage",  
    storeAuthStateInCookie: false,  
  },
};

// MSAL instance for Azure AD B2C
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  msalInstance.handleRedirectPromise().then((authResult) => {
    if (authResult) {
      console.log("User logged in:", authResult);
    }
  }).catch((error) => {
    console.error("Error during login redirect:", error);
  });
});

// Login component
const LoginButton = () => {
  const handleLogin = () => msalInstance.loginRedirect().catch(e => console.error(e));
  
  return (
    <div className="d-lg-flex half">
      <div className="bg order-1 order-md-2" style={{ backgroundImage: "url(static/desk-login.jpg)", backgroundSize: 'cover' }}></div>
      <div className="contents order-2 order-md-1">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7 col-12">
              
            <div className="logo-container" style={{ marginBottom: '20px' }}>
                    <img src={Contoso} alt="MedMate Logo" width={200} />
                  </div>

                  <h3 style={{ fontWeight: 500, fontSize: '2em' }}>Welkom bij <strong>MedMate!</strong></h3>
                  <p className="mb-4" style={{ paddingRight: '45px' }}>
                    Ik help (bedrijfs)artsen met efficiënter documenteren en de kwaliteit verhogen!
                  </p>

                  <button className="login-btn" onClick={handleLogin} style={{ marginTop: '20px' }}>
                    <img src="static/microsoft-logo.png" alt="microsoft-logo" width={25} />
                    Aanmelden met Microsoft
                  </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Logout component
const LogoutButton = () => {
  const handleLogout = () => {
    msalInstance.logoutPopup().catch(e => console.error(e));
  };
  return <button onClick={handleLogout}>Logout</button>;
};

// Main component with authentication logic
const Main = () => {
//  const isAuthenticated = useIsAuthenticated();

  const [isLoading, setIsLoading] = useState(true); // Loading state
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    // Check for authentication status
    instance.handleRedirectPromise()
      .then((authResult) => {
        if (authResult) {
          console.log("User logged in:", authResult);
        }
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false once the check is complete
      })
      .catch((error) => {
        console.error("Error during login redirect:", error);
        setIsLoading(false); // Ensure loading is stopped on error as well
      });
  }, [instance]);

  if (isLoading) {
    // Render loading spinner or placeholder during authentication check
    return <div>Loading...</div>; // You can use a spinner or any custom loader here
  }


  return (
    <AppStateProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={isAuthenticated ? <Chat /> : <LoginButton />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </HashRouter>
    </AppStateProvider>
  );
};

// Rendering the app with MSAL Provider for authentication
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Main />
    </MsalProvider>
  </React.StrictMode>
);